export default {
    init(container: HTMLElement) {
        if (!container) return;

        const banners = document.querySelectorAll('.banner');
        if (!banners) return;

        const header = document.querySelector('header') as HTMLElement;
        if (!header) return;

        header.classList.add('banner-active');
    }
};

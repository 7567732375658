import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import anime from 'animejs';

export default {
    init() {
        //////////// Navigation
        const menu = document.getElementById('menu');
        const hamburger = document.getElementById('hamburger');
        const header = document.querySelector('.header-menu') as HTMLElement;
        const body = document.querySelector('body');

        if (!menu || !hamburger || !header || !body) {
            return;
        }

        if (hamburger) {
            const navItems = header.querySelector('.nav-items') as HTMLElement;

            hamburger.addEventListener('click', function () {
                const timeline = anime.timeline({
                    duration: 400,
                    easing: 'easeOutQuint'
                });

                // close menu
                if (menu.classList.contains('open')) {
                    menu.classList.remove('open');
                    hamburger.classList.remove('close');
                    header.classList.remove('open-menu');
                    header.classList.remove('open-menu');
                    navItems.classList.add('lg:flex');

                    timeline.add({
                        targets: '#menu',
                        opacity: 0,
                        complete: () => {
                            clearBodyLocks();
                        }
                    });
                } else {
                    // open menu
                    lock(header);
                    menu.classList.add('open');
                    hamburger.classList.add('close');
                    header.classList.add('open-menu');
                    navItems.classList.remove('lg:flex');

                    timeline.add({
                        targets: '#menu',
                        opacity: 1
                    });
                }
            });
        }
    }
};

import * as Klaro from 'klaro';

export default {
    init() {
        const cookieBannerFields = document.querySelector(
            '#cookie-banner-fields'
        ) as HTMLElement;

        if (!cookieBannerFields) return;

        const showModal = Boolean(cookieBannerFields.dataset.showModal);
        let cookieBannerText;

        if (cookieBannerFields.dataset.cookieText) {
            cookieBannerText = JSON.parse(
                cookieBannerFields.dataset.cookieText
            );
        }

        // retrieve the first key, this is the lang abbreviation - we get usually on translation from craft, depending on the current language
        const langAbbreviations = Object.keys(cookieBannerText);
        const langAbbreviation =
            langAbbreviations.length > 0 ? langAbbreviations[0] : null;

        const translation = {} as any;
        if (langAbbreviation) {
            const cookieBannerTranslations = cookieBannerText[langAbbreviation];
            translation[langAbbreviation] = {
                privacyPolicyUrl:
                    cookieBannerTranslations.privacyPolicyUrl ?? '',
                consentNotice: {
                    description:
                        cookieBannerTranslations.consentNoticeDescription ?? '',
                    learnMore:
                        cookieBannerTranslations.consentNoticeLearnMore ?? ''
                },
                consentModal: {
                    title: cookieBannerTranslations.consentModalTitle ?? '',
                    description:
                        cookieBannerTranslations.consentModalDescription ?? '',
                    privacyPolicy: {
                        text: cookieBannerTranslations.consentModalPrivacyPolicyText
                    }
                },
                ok: cookieBannerTranslations.ok,
                decline: cookieBannerTranslations.decline,
                acceptSelected: cookieBannerTranslations.acceptSelected,
                save: cookieBannerTranslations.save,
                close: cookieBannerTranslations.close,
                acceptAll: cookieBannerTranslations.acceptAll,
                purposes: {
                    functional:
                        cookieBannerTranslations.purposes[0]?.functional ?? '',
                    essential:
                        cookieBannerTranslations.purposes[0]?.essential ?? '',
                    analytics:
                        cookieBannerTranslations.purposes[0]?.analytics ?? '',
                    marketing:
                        cookieBannerTranslations.purposes[0]?.marketing ?? ''
                }
            };
        }

        const config = {
            elementID: 'cookie-banner',
            storageMethod: 'cookie',
            storageName: 'klaro-cookie',
            mustConsent: showModal, // Cookie banner is a Modal
            acceptAll: true,
            hideDeclineAll: false,
            translations: translation,
            services: [
                // Essential cookies - technically necessary services for the basic functionality of the website
                {
                    name: 'functional',
                    title: 'Craft CMS',
                    default: true,
                    purposes: ['essential'],
                    cookies: [
                        ['CraftSessionId'],
                        ['*_identity'],
                        ['*_username'],
                        ['CRAFT_CSRF_TOKEN']
                    ],
                    required: true
                },
                {
                    name: 'klaro',
                    title: 'Klaro!',
                    purposes: ['essential'],
                    required: true
                },
                {
                    name: 'google-analytics',
                    title: 'Google Analytics',
                    purposes: ['analytics'],
                    cookies: [
                        [/^_ga.*$/i, '/', '.riedenbauer.com'],
                        ['_gid', '/', '.riedenbauer.com'],
                        [/^_ga.*$/i, '/', '.riedenbauer-craft-cms.test'], // for local page
                        ['_gid', '/', '.riedenbauer-craft-cms.test'] // for local page
                    ],
                    required: false
                }
            ]
        };

        // we assign the Klaro module to the window, so that we can access it in JS
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w = window as any;
        w.klaro = {};
        w.klaro = Klaro;
        w.klaroConfig = config;
        // we set up Klaro with the config
        Klaro.setup(config);
    }
};

export default {
    init() {
        const forms = document.querySelectorAll(
            'div[data-form-id]'
        ) as NodeListOf<HTMLElement>;

        if (forms.length === 0) return;

        forms.forEach((form) => {
            const formId = form.dataset.formId;

            if (!formId) return;

            const currentForm = form.querySelector('#' + formId);

            if (!currentForm) return;

            const formHandle = currentForm.querySelector(
                'input[name="handle"]'
            ) as HTMLInputElement;

            if (!formHandle) return;

            //Find the CSRF token hidden input, so we can replace it
            let csrfInput = currentForm.querySelector(
                'input[name="CRAFT_CSRF_TOKEN"]'
            ) as HTMLInputElement;

            if (!csrfInput) return;

            // Fetch the new token for the form and replace the CSRF input with our new one
            fetch(
                '/actions/formie/forms/refresh-tokens?form=' + formHandle.value
            )
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    csrfInput = result.csrf.input;

                    if (!form) return;

                    const formInputField = form.querySelector(
                        'input[name="' + result.csrf.param + '"]'
                    ) as HTMLInputElement;

                    if (!formInputField) return;

                    formInputField.value = result.csrf.token;
                });
        });
    }
};

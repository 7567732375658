import './app.scss';
import 'lazysizes';
import swiper from './ts/swiper';
import 'intersection-observer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import navigation from './ts/navigation';
import form from './ts/form';
import cookieBanner from './ts/cookie-banner';
import youtubeVideo from './ts/youtube-video';
import banner from './ts/banner';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    navigation.init();

    AOS.init();

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(
                swiperElement,
                JSON.parse(swiperElement.dataset.swiperOptions)
            );
        });
    }

    form.init();

    cookieBanner.init();

    const youtubeVideoContainers = document.querySelectorAll(
        '.youtube-video-container'
    ) as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        youtubeVideoContainers.forEach((container) => {
            youtubeVideo.init(container);
        });
    }

    const banners = document.querySelectorAll(
        '.banner'
    ) as NodeListOf<HTMLElement>;
    if (banners.length) {
        banners.forEach((container) => {
            banner.init(container);
        });
    }
})();
